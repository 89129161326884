import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { Method, Service, autheidServiceUrl, environment } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { BaseProps } from 'shared-components/utils/types';

import { useApp } from './AppProvider';

type AuthEIDContextProps = {
  selectorInitExecute: () => void;
  selectorInitStop: () => void;
  logout: () => void;
  expire: number;
  token: string;
  created: string;
  requestId: string;
  isAdmin: number;
  lockTimeout: boolean;
};

const AuthEIDContext = createContext<AuthEIDContextProps>({
  selectorInitExecute: function (): void {
    throw new Error('Function not implemented.');
  },
  selectorInitStop: function (): void {
    throw new Error('Function not implemented.');
  },
  logout: function (): void {
    throw new Error('Function not implemented.');
  },

  expire: 0,
  token: '',
  created: '',
  requestId: '',
  isAdmin: 0,
  lockTimeout: false
});

export const useAuthEID = () => {
  const context = useContext(AuthEIDContext);
  if (!context) {
    throw new Error('GatewayContext not available');
  }
  return context;
};

const AuthEIDProvider = ({ children }: BaseProps) => {
  const {
    values: { status, serviceWorkerReady },
    setters: { setNeedAuth }
  } = useApp();

  const [needRenew, setNeedRenew] = useState<boolean>(false);
  const [stop, setStop] = useState(false);
  const [expire, setExpire] = useState(-1);
  const [token, setToken] = useState('');
  const [created, setCreated] = useState('');
  const [requestId, setRequestId] = useState('');
  const [lockTimeout, setLockTimeout] = useState<boolean>(false);

  const selectorInit = useSWQuery({
    service: Service.AUTHEID,
    method: Method.QUERY,
    returnObjectName: 'selector',
    data: {
      api: 'selector',
      method: 'selector_init',
      args: {
        service_url: autheidServiceUrl
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const requestId = data.data.request_id;
      const status = data.data.status;
      if (requestId) {
        setRequestId(requestId);
      }
      switch (status) {
        case 'SUCCESS': {
          //          setExpire(100);
          setExpire(data.data.expires_in);
          setToken(data.data.access_token);
          setCreated(data.data.account_created);
          setStop(true);
          setNeedAuth(true);
          setNeedRenew(true);
          break;
        }
        case 'TIMEOUT': {
          if (stop === false && lockTimeout === false) {
            selectorInitExecute();
          }
          break;
        }
      }
    }
  });

  const _stopLoginRenew = useSWQuery({
    service: Service.AUTHEID,
    method: Method.STOP_TOKEN_RENEW,
    returnObjectName: 'nonexistent',
    data: null,
    auto: false
  });

  const selectorInitExecute = useCallback(() => {
    if (lockTimeout === false) {
      setStop(false);
      selectorInit.execute();
      setLockTimeout(true);
      setTimeout(() => {
        console.log('TIMEOUT');
        setLockTimeout(false);
      }, 57_000);
    }
  }, [lockTimeout, selectorInit]);

  const selectorInitStop = useCallback(() => {
    setStop(true);
  }, [setStop]);

  const logout = useCallback(() => {
    setStop(true);
    setExpire(-1);
    setToken('');
    setCreated('');
    setRequestId('');
  }, [setCreated, setExpire, setStop, setToken]);

  useEffect(() => {
    if (serviceWorkerReady === false) {
      return;
    }
    if (expire === undefined || expire === -1) {
      setStop(false);
    } else {
      setStop(true);
      if (needRenew) {
        /*
        loginRenew.execute({
          data: {
            args: {
              access_token: token
            },
            timer: expire
          }
        });
        */
        setNeedRenew(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expire, needRenew]);

  useEffect(() => {
    if (serviceWorkerReady === true) {
      setExpire(status.expire);
      setToken(status.accessToken);
    }
  }, [serviceWorkerReady, status]);
  return (
    <AuthEIDContext.Provider
      value={{
        selectorInitStop,
        selectorInitExecute,
        logout,
        lockTimeout,
        expire,
        token,
        created,
        requestId,
        isAdmin: environment.includes('admin') ? 1 : 0
      }}
    >
      {children}
    </AuthEIDContext.Provider>
  );
};

export default AuthEIDProvider;
