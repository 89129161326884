import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import useSWComm from '../hooks/useSWComm';
import { BaseProps } from 'shared-components/utils/types';
import { Method, Service } from 'shared-components/configuration';
import { serviceworkerRegister, unregister } from 'shared-components/serviceWorker';

let forceReloadTimer: any;
let keepAliveTimer: any;
type AppContextProps = {
  values: {
    protectedSite: boolean;
    loggedIn: boolean;
    serviceWorkerReady: boolean;
    status: any;
    needAuth: boolean;
    searchBox?: string;
  };
  setters: {
    setProtected: (value: boolean) => void;
    setLoggedIn: (value: boolean) => void;
    setNeedAuth: (value: boolean) => void;
    setSearchBox: (value?: string) => void;
  };
};

const initialValues: AppContextProps = {
  values: {
    protectedSite: false,
    loggedIn: false,
    serviceWorkerReady: false,
    status: {},
    needAuth: false,
    searchBox: undefined
  },
  setters: {
    setProtected: function (_: boolean): void {
      throw new Error('Function not implemented.');
    },
    setLoggedIn: function (_: boolean): void {
      throw new Error('Function not implemented.');
    },
    setSearchBox: function (_?: string): void {
      throw new Error('Function not implemented.');
    },
    setNeedAuth: function (_: boolean): void {
      throw new Error('Function not implemented.');
    }
  }
};

const AppContext = createContext(initialValues);

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('GatewayContext not available');
  }
  return context;
};

const AppProvider = ({ children }: BaseProps) => {
  const [protectedSite, setProtected] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [serviceWorkerReady, setServiceWorkerReady] = useState<boolean>(false);
  const [status, setStatus] = useState<any>({});
  const [needAuth, setNeedAuth] = useState<boolean>(false);
  const [searchBox, setSearchBox] = useState<string | undefined>();
  const regsiterCallback = useCallback(async () => {
    const reg = serviceworkerRegister({ scope: '/' });
    reg.then(() => {
      console.log(reg);
      navigator.serviceWorker.startMessages();
      //reg.active.startMessages();
      reg.active?.postMessage({ service: 'init' });
      if (forceReloadTimer === undefined) {
        forceReloadTimer = setTimeout(() => {
          unregister().then(() => {
            location.reload();
          });
        }, 5000);
      }
      if (navigator.serviceWorker.controller !== undefined) {
        navigator.serviceWorker.controller?.postMessage({ service: 'init' });
      }
    });
  }, []);
  useEffect(() => {
    regsiterCallback();
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useSWComm({
    service: Service.SERVICEWORKER,
    method: Method.STATUS,
    onResponse: (data: any) => {
      clearTimeout(forceReloadTimer);
      setStatus(data);
      //setLoggedIn(true);
      setLoggedIn(data.graphqlAuthorized);
      setTimeout(() => {
        setServiceWorkerReady(true);
      }, 1000);
      if (keepAliveTimer === undefined) {
        keepAliveTimer = setInterval(() => {
          App.sendMessage({ service: 'keepalive' });
        }, 1000);
      }
    }
  });

  const values: AppContextProps = {
    values: {
      protectedSite,
      loggedIn,
      serviceWorkerReady,
      status,
      needAuth,
      searchBox
    },
    setters: {
      setProtected,
      setLoggedIn,
      setNeedAuth,
      setSearchBox
    }
  };
  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export default AppProvider;
