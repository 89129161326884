import React, { useCallback, useEffect, useState } from 'react';
import DataTable, { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { DtPicker } from 'react-calendar-datetime-picker';

import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

//import { useMerchants } from '../../../contexts/GraphQL';
import { PendingPaymentType } from '../../../types/grapql';
import useRfqs from '../../../hooks/useRfqs';
//import { useMerchantInfo } from 'shared-components/providers/MerchantInfoProvider';
//import { useAuthEID } from 'shared-components/providers/AuthEIDProvider';
import { useApp } from 'shared-components/providers/AppProvider';
import { convertUTCtoCET, dateConversionFromCalendar } from 'shared-components/utils';

import styles from './style.module.scss';

export const ExpandedComponent: React.FC<ExpanderComponentProps<PendingPaymentType>> = ({ data }) => {
  return (
    <>
      {data.kycData ? (
        <div className={styles.kycContent}>
          <Row>
            <Col xs={12} md={6}>
              <Row className={styles.kycField}>
                <Col xs={4} className={styles.label}>
                  Transaction ID
                </Col>
                <Col xs={8} className={styles.value}>
                  {data.operationId}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row className={styles.kycField}>
                <Col xs={4} className={styles.label}>
                  Personal ID
                </Col>
                <Col xs={8} className={styles.value}>
                  {data.clientPid}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row className={styles.kycField}>
                <Col xs={4} className={styles.label}>
                  Receiving IBAN/BBAN
                </Col>
                <Col xs={8} className={styles.value}>
                  {data.iban !== null && data.iban!.length > 0 ? data.iban : data.bban}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row className={styles.kycField}>
                <Col xs={4} className={styles.label}>
                  Sending IBAN/BBAN
                </Col>
                <Col xs={8} className={styles.value}>
                  {data.clientIban !== null && data.clientIban!.length > 0 ? data.clientIban : data.clientBban}
                </Col>
              </Row>
            </Col>

            {Object.keys(data.kycData!)?.map((val, key) => {
              const value = (data.kycData as any)[val] ?? '';
              if (typeof value === 'object') {
                return (
                  <>
                    {Object.keys(value)?.map((val, key) => {
                      const intval = (value as any)[val] ?? '';
                      return (
                        <Col key={key} xs={12} md={6}>
                          <Row className={styles.kycField}>
                            <Col xs={4} className={styles.label}>
                              {val}
                            </Col>
                            <Col xs={8} className={styles.value}>
                              {intval}
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                  </>
                );
              }
              return (
                <Col key={key} xs={12} md={6}>
                  <Row className={styles.kycField}>
                    <Col xs={4} className={styles.label}>
                      {val}
                    </Col>
                    <Col xs={8} className={styles.value}>
                      {value}
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : (
        <div />
      )}
      {data.depositData ? (
        <div className={styles.kycContent}>
          <Row>
            {Object.keys(data.depositData!)?.map((val, key) => {
              const value = (data.depositData as any)[val] ?? '';
              return (
                <Col key={key} xs={12} md={6}>
                  <Row className={styles.kycField}>
                    <Col xs={4} className={styles.label}>
                      {val}
                    </Col>
                    <Col xs={8} className={styles.value}>
                      {value}
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

const Rfq = () => {
  const { pid } = useParams();
  const {
    values: { searchBox },
    setters: { setSearchBox }
  } = useApp();
  const today = new Date();

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const [dateRange, setDateRange] = useState<any>({
    from: {
      year: yesterday.getFullYear(),
      month: yesterday.getMonth() + 1,
      day: yesterday.getDate()
    },
    to: {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate()
    }
  });

  const { data, fetchList, generateCsv, csvGenerator } = useRfqs();
  //const { name } = useMerchantInfo();
  //const { merchants } = useMerchants();
  //const { isAdmin } = useAuthEID();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterModalVisible, setFilterModalVisible] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>(dateConversionFromCalendar(dateRange));
  useEffect(() => {
    setSearchBox('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handlePageChange(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handlePageChange(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, searchBox, filters]);

  useEffect(() => {
    setTotalRows(data.totalCount);
    setLoading(false);
  }, [data]);

  const handlePageChange = (page: number) => {
    setLoading(true);
    const totalPages = Math.ceil(data.totalCount / perPage);
    if (page === 1) {
      fetchList({ txRef: searchBox, pid, first: perPage, ...filters });
    } else if (page >= totalPages) {
      fetchList({ txRef: searchBox, pid, last: perPage, ...filters });
    } else if (page > currentPage) {
      fetchList({ txRef: searchBox, pid, first: perPage, after: data.pageInfo.endCursor, ...filters });
    } else if (page < currentPage) {
      fetchList({ txRef: searchBox, pid, last: perPage, before: data.pageInfo.startCursor, ...filters });
    } else {
      fetchList({ txRef: searchBox, pid, first: perPage, after: data.pageInfo.endCursor, ...filters });
    }
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, _: any) => {
    setPerPage(newPerPage);
  };

  const handleApplyFilters = useCallback(
    (event: any) => {
      event.preventDefault();
      const operationId = event.currentTarget.elements.operationId.value || undefined;
      const clientPid = event.currentTarget.elements.clientPid.value || undefined;
      const clientIban = event.currentTarget.elements.clientIban.value || undefined;
      const txRef = event.currentTarget.elements.txRef.value || undefined;
      const vFrom = dateRange?.from || undefined;
      const vTo = dateRange?.to || undefined;
      const fromDate =
        vFrom === undefined
          ? undefined
          : `${vFrom.year}-${vFrom.month.toString().padStart(2, '0')}-${vFrom.day.toString().padStart(2, '0')}`;
      const toDate =
        vTo === undefined
          ? undefined
          : `${vTo.year}-${vTo.month.toString().padStart(2, '0')}-${vTo.day.toString().padStart(2, '0')}`;
      //    const fromDate = event.currentTarget.elements.fromDate.value || undefined;
      //    const toDate = event.currentTarget.elements.toDate.value || undefined;
      const eurAmount = event.currentTarget.elements.eurAmount.value || undefined;
      const sekAmount = event.currentTarget.elements.sekAmount.value || undefined;

      setFilters({
        operationId,
        clientPid,
        clientIban,
        txRef,
        fromDate,
        toDate,
        eurAmount,
        sekAmount
      });
    },
    [dateRange]
  );

  const columns: TableColumn<PendingPaymentType>[] = [
    {
      name: 'ID',
      cell: (row) => {
        if (row.id) {
          return atob(row.id).split(':').at(1) || '';
        }
        return '';
      },
      wrap: true,
      width: '80px'
    },
    {
      name: 'TX ref',
      selector: (row) => row.txRef ?? '',
      wrap: true
    },
    {
      name: 'Company name',
      selector: (row) => {
        return row.merchantName;
        //        console.log(merchants);
        //        console.log(merchants?.find((merchant) => merchant.email === row.email));
        //return isAdmin ? merchants?.find((merchant) => merchant.email === row.email)?.name || '' : row.merchantName;
      },
      wrap: true,
      minWidth: '150px'
    },
    {
      name: 'Status',
      selector: (row) => row.status ?? '',
      wrap: false,
      minWidth: '150px'
    },
    {
      name: 'KYC fee',
      selector: (row) => row.kycFee,
      wrap: true,
      width: '93px'
    },
    {
      name: 'SEK deposit',
      selector: (row) => row.sekDeposit.toFixed(2) ?? '', //TODO: replace by eurRequest
      wrap: true,
      width: '120px'
    },
    {
      name: 'Created at',
      selector: (row) => convertUTCtoCET(row.createdAt) ?? '',
      width: '180px'
    },
    {
      name: 'Settled at',
      selector: (row) => convertUTCtoCET(row.settledAt) ?? '',
      width: '180px'
    },
    {
      name: 'Live',
      selector: (row) => row.live ?? '',
      width: '180px'
    }
  ];

  /*
  useEffect(() => {
    handlePageChange(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);
  */
  return (
    <>
      <Modal
        show={filterModalVisible}
        onHide={() => {
          setFilterModalVisible(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleApplyFilters}>
          <Modal.Header closeButton>RFQ Filter</Modal.Header>
          <Modal.Body>
            <FloatingLabel controlId="dateRange" label="" className="mb-2">
              <DtPicker
                type="range"
                onChange={setDateRange}
                initValue={dateRange}
                clearBtn={true}
                yearListStyle="list"
                inputClass="form-control form-control-sm"
                placeholder="Select date range"
              />
            </FloatingLabel>
            <br />
            <FloatingLabel controlId="operationId" label="Operation ID" className="mb-2">
              <Form.Control type="text" size="sm" placeholder="Operation ID" defaultValue={filters?.operationId} />
            </FloatingLabel>
            <FloatingLabel controlId="clientPid" label="Customer SSN" className="mb-2">
              <Form.Control type="text" size="sm" placeholder="Customer SSN" defaultValue={filters?.clientPid} />
            </FloatingLabel>
            <FloatingLabel controlId="clientIban" label="Customer IBAN" className="mb-2">
              <Form.Control type="text" size="sm" placeholder="Customer IBAN" defaultValue={filters?.clientIban} />
            </FloatingLabel>
            <FloatingLabel controlId="txRef" label="TX Ref" className="mb-2">
              <Form.Control type="text" size="sm" placeholder="TX Ref" defaultValue={filters?.txRef} />
            </FloatingLabel>
            <FloatingLabel controlId="eurAmount" label="EUR amount" className="mb-2">
              <Form.Control type="text" size="sm" placeholder="EUR amount" defaultValue={filters?.eurAmount} />
            </FloatingLabel>
            <FloatingLabel controlId="sekAmount" label="SEK amount" className="mb-2">
              <Form.Control type="text" size="sm" placeholder="SEK amount" defaultValue={filters?.sekAmount} />
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="px-4"
              type="submit"
              onClick={() => {
                setFilterModalVisible(false);
              }}
            >
              Apply filters
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <div></div>
      <DataTable
        title={
          <Row>
            <Col>Deposits</Col>
            <Col sm={3}>
              <Button
                variant="outline"
                onClick={() => {
                  setFilterModalVisible(true);
                }}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
              <Button
                variant="outline-success"
                size="sm"
                disabled={csvGenerator}
                onClick={() => {
                  generateCsv(filters);
                }}
              >
                {csvGenerator === true && <Spinner size="sm" />} Download CSV
              </Button>
            </Col>
          </Row>
        }
        highlightOnHover={true}
        columns={columns}
        data={data.data ?? []}
        progressPending={loading}
        pagination
        paginationServer
        paginationPerPage={30}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={{
          headCells: {
            style: {
              backgroundColor: 'lightgray',
              textTransform: 'uppercase'
            }
          }
        }}
        className={clsx(styles.table, 'shadow p-3 mb-5 bg-white overflow-auto')}
        expandOnRowClicked
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        expandableInheritConditionalStyles
      />
    </>
  );
};
export default Rfq;
